import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UnsplashService {
  constructor(private apiService: ApiService) {}

  getPhotos(page: number) {
    return this.apiService.get(`/unsplash/${page}`);
  }

  searchPhotosByKeword(query: string, page: number) {
    return this.apiService.get(`/unsplash/${query}/${page}`);
  }

  trackDownload(id: string, ixid: string) {
    return this.apiService.get(`/unsplash/${id}/${ixid}`);
  }
}
