<ion-header class="modal-header">
  <div class="modal-title">
    <h3>{{ isFirstSlide ? 'Choose a file' : 'Crop Image' }}</h3>
  </div>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-slides #slides mode="ios" (ionSlidesDidLoad)="onViewDidLoad()">
    <ion-slide class="slide">
      <label
        for="file-uploader"
        class="custom-file-upload"
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragOut($event)"
        [ngClass]="{ 'droparea-active': isDraggingFile, droparea: !showStockPhotos }"
      >
        <ion-icon color="primary" slot="start" name="cloud-upload-outline"></ion-icon>Upload from device or drag file
        <div class="helper-text" *ngIf="helperText">{{ helperText }}</div>
      </label>
      <input id="file-uploader" class="file-input" type="file" (change)="detectPhotoUpload($event)" />

      <div *ngIf="showStockPhotos">
        <ion-searchbar
          [ngModel]="searchValue"
          (ionChange)="onSearchInput($event)"
          (ionClear)="onClearSearch()"
          (debounce)="(1000)"
          placeholder="Search for a stock photo..."
          style="padding: 0 20px"
          showClearButton="always"
        >
        </ion-searchbar>
        <div class="container">
          <ion-card class="photo-card" *ngFor="let photo of unsplashPhotos">
            <img
              class="image"
              alt="{{ photo.alt_description }}"
              src="{{ photo.urls.small }}"
              (click)="onPhotoClick(photo.id, photo.urls.regular, photo.links.download_location)"
            />
            <div class="label">
              <ion-card-subtitle class="text">
                Photo by
                <a
                  target="_blank"
                  href="https://unsplash.com/@{{ photo.user.username }}?utm_source=unthread&utm_medium=referral"
                  >{{ photo.user.name }}</a
                >
                on <a target="_blank" href="https://unsplash.com?utm_source=unthread&utm_medium=referral">Unsplash</a>
              </ion-card-subtitle>
            </div>
          </ion-card>
          <ion-infinite-scroll (disabled)="(!isFirstSlide)" (ionInfinite)="doInfinite($event)">
            <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more photos...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>
      </div>
    </ion-slide>

    <ion-slide class="slide">
      <image-cropper
        [imageChangedEvent]="isUnsplashPhoto ? null : imageChangedEvent"
        [imageURL]="isUnsplashPhoto ? photoUrl : null"
        [imageBase64]="imageBase64String"
        [maintainAspectRatio]="!!aspectRatio"
        [aspectRatio]="aspectRatio"
        format="png"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </ion-slide>
  </ion-slides>
</ion-content>

<ion-footer *ngIf="!isFirstSlide" class="crop-footer">
  <ion-button fill="clear" (click)="onBackButtonClick()">
    <ion-icon slot="start" name="arrow-back-outline"></ion-icon> Back
  </ion-button>
  <ion-button color="success" (click)="onFinishButtonClick()">
    <ion-icon slot="start" name="checkmark-outline"></ion-icon> Finish
  </ion-button>
</ion-footer>
