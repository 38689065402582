import { Component, OnInit, ViewChild } from '@angular/core';
import { uuidv4 } from '@firebase/util';
import { ModalController, IonSlides } from '@ionic/angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { Upload, UnsplashPhoto } from 'src/models';
import { AnalyticsService } from 'src/services/analytics.service';
import { MessageService } from 'src/services/message.service';
import { UnsplashService } from 'src/services/unsplash.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements OnInit {
  @ViewChild(IonSlides, { static: false }) ionSlides: IonSlides;
  isKeywordSearch = false;
  isFirstLoad = false;
  isFirstSlide = true;
  isUnsplashPhoto = false;
  showStockPhotos: boolean;
  aspectRatio: number;
  helperText: string;
  searchValue: string;
  photoUrl: string;
  uploadFile: Upload;
  unsplashPhotos: UnsplashPhoto[] | any[] = [];
  photoId: string;
  downloadLocationUrl: string;
  page = 1;
  throttle = 0;
  distance = 2;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isDraggingFile = false;
  imageBase64String: string | ArrayBuffer;
  fileName: string;

  constructor(
    private modalCtrl: ModalController,
    private msgSrvc: MessageService,
    private unsplashService: UnsplashService,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    if (this.showStockPhotos) {
      this.getUnsplashPhotos(false, '');
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDraggingFile = false;
    let dt = event.dataTransfer;
    let files = dt.files;
    const file = files[0];

    if (file.type.startsWith('image/')) {
      // Read the image file and convert it to base64
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;

        this.imageBase64String = base64String;
        this.handleUpload(files, event);
      };

      reader.readAsDataURL(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDraggingFile = true;
  }

  onDragOut(event: DragEvent): void {
    event.preventDefault();
    this.isDraggingFile = false;
  }

  detectPhotoUpload(event) {
    this.analyticsService.trackEvent('Image Modal', 'Upload file');

    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    this.handleUpload(files, event);
  }

  handleUpload(files: FileList, event) {
    this.fileName = files[0].name;

    // Handle gifs separately without the cropper
    if (files.length) {
      const file = files[0];

      if (file.type === 'image/gif' || !file.type.startsWith('image/')) {
        this.uploadFile = new Upload(file);
        this.dismiss();

        return;
      }
    }

    this.imageChangedEvent = event;
    this.isUnsplashPhoto = false;

    this.ionSlides.lockSwipes(false);
    this.ionSlides.slideNext(1000);
    this.isFirstSlide = false;
    this.ionSlides.lockSwipes(true);
  }

  async getUnsplashPhotos(isFirstLoad: boolean, event: any) {
    this.isKeywordSearch = false;
    this.unsplashService.getPhotos(this.page).subscribe((unsplashPhotos: UnsplashPhoto[]) => {
      if (!isFirstLoad) {
        this.unsplashPhotos = unsplashPhotos;
      } else {
        this.unsplashPhotos.push(...unsplashPhotos);
        event.target.complete();
      }
      this.page++;
    });
  }

  async searchPhoto(isFirstLoad: boolean, searchValue: string, event: any) {
    this.unsplashService.searchPhotosByKeword(searchValue, this.page).subscribe((unsplashPhotos: UnsplashPhoto[]) => {
      if (!isFirstLoad) {
        this.unsplashPhotos = unsplashPhotos;
      } else {
        this.unsplashPhotos.push(...unsplashPhotos);
        event.target.complete();
      }
      this.page++;
    });
  }

  async triggerUnsplashDownload(id: string, url: string) {
    const ixid = new URLSearchParams(new URL(url).search).get('ixid');

    this.unsplashService.trackDownload(id, ixid).subscribe();
  }

  onSearchInput(event: any) {
    const { value } = event.target;

    if (!value) {
      return;
    }

    this.isKeywordSearch = true;
    this.page = 1;
    this.searchPhoto(false, value, '');
  }

  onClearSearch() {
    this.page = 1;
    this.searchValue = '';
    this.getUnsplashPhotos(false, '');
  }

  async onPhotoClick(id: string, photoUrl: string, downloadLocationUrl: string) {
    this.isUnsplashPhoto = true;
    this.photoUrl = photoUrl;
    this.photoId = id;
    this.downloadLocationUrl = downloadLocationUrl;

    this.ionSlides.lockSwipes(false);
    this.ionSlides.slideNext(300);
    this.isFirstSlide = false;
    this.ionSlides.lockSwipes(true);
  }

  onBackButtonClick() {
    this.ionSlides.lockSwipes(false);
    this.isFirstSlide = true;
    this.ionSlides.slidePrev(300);
    this.ionSlides.lockSwipes(true);
  }

  async onFinishButtonClick() {
    this.analyticsService.trackEvent('Image Modal', 'Select Unsplash photo');

    try {
      const uniqueId = uuidv4();
      const imageFile = this.dataURItoBlob(
        this.croppedImage,
        this.fileName ?? `${uniqueId}-${this.isUnsplashPhoto ? 'unsplash' : 'upload'}`,
      );
      const upload = new Upload(imageFile);

      if (this.isUnsplashPhoto) {
        await this.triggerUnsplashDownload(this.photoId, this.downloadLocationUrl);
      }

      this.uploadFile = upload;
      this.dismiss();
    } catch (err) {
      this.msgSrvc.show(err);
    }
  }

  onViewDidLoad() {
    this.ionSlides.lockSwipes(true);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.msgSrvc.show('Failed to load image. Please try again.');
  }

  dataURItoBlob(dataURI: any, fileName: string): File {
    // convert base64/URLEncoded data component to a file
    let byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }

  doInfinite(event: any) {
    setTimeout(() => {
      if (this.isKeywordSearch) {
        this.searchPhoto(true, this.searchValue, event);
      } else {
        this.getUnsplashPhotos(true, event);
      }
    }, 1000);
  }

  dismiss() {
    this.modalCtrl.dismiss({
      uploadFile: this.uploadFile || null,
      fileName: this.fileName || null,
    });
  }
}
